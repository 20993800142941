import {useState, useEffect} from 'react'
import AlertDialog from "../ui/AlertDialog";
import {useApp} from "../../context/appContext";
import {IoChevronBack, IoCaretDownOutline} from "react-icons/io5";
import ProgressModal from "../ui/ProgressModal";
import NotConection from "../ui/NotConection";



export default function UserStudyForm({open, setOpen, isComplete, userId, setMessage, handleClosed}) {
    const {online, setLoading, urlbase, myHeaders} = useApp();
    const [alert, setAlert ] = useState(null);
    const [isload, setIsLoad] = useState(true);
    const [studies, setStudies] = useState([]);
    const [user, setUser] = useState({
        name: "",
        lastname: "",
        study: ""
    });

    const handleBack = (e) => {
        setTimeout(() => {
            handleClosed();
            setOpen(false);
            setAlert(null);
            window.scrollTo(0,0);
            setIsLoad(true);
        }, 200);
    }
    const handleSubmit = async (e) => {
        let button = e.currentTarget;
        try {
            e.preventDefault();
            if (user.study != "") {
                setLoading(true);
                button.disabled = true;
                var response = response = await fetch(urlbase+"/app/update_study_user/"+userId, {
                    method: 'PUT',
                    headers: myHeaders(),
                    body: JSON.stringify(user)
                });
                const res = await response.json();
                if(res.clave && res.clave == "exito"){
                    if (userId) {
                        setMessage({text: "Estudio actualizado", type:"done"});
                    }else{
                        setMessage({text: "Estudio guardado", type:"done"});
                    }
                    setOpen(false);
                    isComplete();
                    setUser({name: "", lastname: "", study: ""});
                    window.scrollTo(0,0);
                    setIsLoad(true);
                }else{
                    setAlert(res.mensaje);
                }
                setLoading(false);
                button.disabled = false;
            }else{
                setAlert("Completa los campos nombre, telefono, genero, rol");
            }
        } catch (err) {
            console.log(err);
            setAlert("Error en el servidor");
            setLoading(false);
            button.disabled = false;
        }
    }
    const loadUser = async (id) => {
        const res = await fetch(urlbase+"/app/get_user_studies/"+id, {
            method: 'GET',
            headers:myHeaders()
        })
        const response = await res.json();
        const data = response.usuario;
        const studiesdb = response.studies;
        setIsLoad(false);
        setStudies(studiesdb);
        setUser({name:data.name, lastname:data.lastname, study:data.study});
    }
    const handleChange = (e) => {
        setUser({...user, [e.target.name]: e.target.value});
        setAlert(null);
    }

    useEffect(() => {
        setUser({name: "", lastname: "", study:""});
        setAlert(null);
        if (userId != null){
            loadUser(userId);
        }else{
            setIsLoad(false);
        }
    }, [userId]);

    useEffect(() => {
        setAlert(null);
    }, [open]);


    if (!online) return <div className="fadeIn">
        <div className="py-2"></div>
        <div className="rounded row w-100">
            <div className="row px-3 w-100">
                <NotConection handleClosed={handleBack}/>
            </div>
        </div>
    </div>;


    if (isload) return <div className="fadeIn">
        <div className="py-2"></div>
        <div className="rounded row w-100">
            <div className="row px-3 w-100">
                <ProgressModal />
            </div>
        </div>
    </div>;

    return (
        <div>
            <div className="head_modal justify-items-center">
                <button className='btn_back waves-effect text-center' onClick={handleBack}>
                    <IoChevronBack className='icon-md' />
                </button>
                <h5 className="modal-tittle">Elegir un estudio</h5>
            </div>
            <div className='w-100 sm-py-2'></div>
            <div className='w-100 sm-py-2'></div>
            <div className="modal_body">
                <div className="row gap-1 w-100">
                    <div className="row w-100">
                        <label className="lb-inp-usm w-100">
                            <div className='row w-100'>
                                <span>Usuario seleccionado </span>
                                <p className='tg-requir w-100 my-1'>{user ? user.name+" "+user.lastname : ''}</p>
                            </div>
                        </label>
                    </div>
                    <div className="row my-1">
                        <label className="lb-inp-usm mb-1">Estudio</label>
                        <div className='position-relative w-100'>
                            <select className="inp_add clear_sl_p" value={user.study} name="study" onChange={handleChange} disabled={user.study && user.study != ''}>
                                <option value="">Elegir...</option>
                                {studies && studies.length > 0 ? studies.map((study, ind) => {
                                    return (<option value={study._id}>{study.name} {study.city}</option>)
                                }) : ''}
                            </select>
                        </div>
                    </div>
                </div>
                {alert !== null ? (<div className="row my-1">
                    <AlertDialog mensaje={alert} setMensaje={setAlert} time={3000} closed={false}/>
                </div>) : ''}
                <div className='w-100 py-3'></div>
                <div className='w-100 py-3'></div>
            </div>
            <div className="modal_footer">
                <div className="d-flex justify-end">
                    <div>
                        <button className="btn btn-primary waves-effect waves-light" type="submit" onClick={handleSubmit}>Aplicar</button>
                    </div>
                </div>
            </div>
        </div>
    )
}