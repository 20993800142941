import {useState, useEffect, useRef} from 'react'
import {IoCall} from "react-icons/io5";
import {IoAddSharp, IoEyeSharp, IoEllipsisVerticalSharp, IoCreate, IoRemoveCircle, IoChevronBack} from "react-icons/io5";
import Modal from "../../components/ui/Modal";
import OptionMovil from "../../components/ui/OptionMovil";
import AdminForm from "../../components/forms/AdminForm"
import SnackBar from "../../components/ui/SnackBar";
import ProgressIntern from "../../components/ui/ProgressIntern";
import {searchTable, extractDateFormatR, extractHour} from "../../components/utility/utils";
import {useApp} from "../../context/appContext";
import { updateLocalUsuarios, getUsuarios } from "../../db";


export default function SuperAdmins(){
    const {online, session, setLoading, urlbase, myHeaders} = useApp();
    const [users, setUsers] = useState([]);
    const [message, setMessage] = useState(false);
    const [isload, setIsLoad] = useState(false);
    const [open, setOpen] = useState(false);
    const [option, setOption ] = useState(false);
    const [openp, setOpenp] = useState(false);
    const [opend, setOpend] = useState(false);
    const [idref, setIdRef] = useState(null);
    const [iduser, setIdUser] = useState(null);
    const [credenciales, setCredenciales] = useState({email:'', password:''});
    const tableSer = useRef(null);

    const handleBack = (e) => {
        setTimeout(() => {
            handleClosed(e);
        }, 200);
    }
    const abrirModal = () => {
        setOpen(true);
    }
    const handleClosed = (e) => {
        setOpen(false);
        setOpend(false);
        setOpenp(false);
        setIdRef(null);
        setIdUser(null);
        e.stopPropagation();
    }
    const loadUsers = async () => {
        if (online) {
            try {
                setIdRef(null);
                setIdUser(null);
                setLoading(true);
                const response = await fetch(urlbase+"/app/get_spusers", {
                    method: 'GET',
                    headers: myHeaders(),
                })
                const data = await response.json();
                console.log(data);
                setUsers(data);
                setIsLoad(true);
                setLoading(false);
                await updateLocalUsuarios(data);
            } catch (err){
                console.log(err);
                console.warn('Fallo al obtener datos online, usando datos offline');
                const data = await getUsuarios();
                setUsers(data);
                setIsLoad(true);
                setLoading(false);
            }
        } else {
            console.warn('Sin conexión, usando datos offline');
            const data = await getUsuarios();
            setUsers(data);
            setIsLoad(true);
            setLoading(false);
        }
    }
    const delUser = async (e, id) => {
        try {
            e.target.disabled = true;
            setLoading(true);
            const response = await fetch(urlbase+"/app/delete_spuser/"+idref, {
                method: 'DELETE',
                headers: myHeaders()
            })
            const data = await response.json();
            setUsers(users.filter((user) => user._id !== idref));
            setMessage({text: "Usuario eliminado", type:"error"});
            setLoading(false);
            setOpend(false);
            setIdRef(null);
            e.target.disabled = false;
        } catch (err) {
            console.log(err);
            setLoading(false);
            e.target.disabled = false;
        }
    }
    const handleDelete = async (e) => {
        setIdRef(e.currentTarget.id);
        setOpend(true);
    }
    const handleEdit = async (e) => {
        setIdUser(e.currentTarget.id);
        setOpen(true);
    }
    const handleOptions = async (e) => {
        setIdRef(e.currentTarget.id);
        setIdUser(e.currentTarget.id);
        setOption(true);
    }
    const showPassword = async (e) => {
        let id = e.currentTarget.id;
        setOpenp(true);
        if (session.user.rol == "Super") {
            setLoading(true);
            const res = await fetch(urlbase+"/app/get_spuser/"+id, {
                method: 'GET',
                headers:myHeaders()
            })
            const response = await res.json();
            const data = response.usuario;
            setCredenciales({email:data.email, password:data.password});
            setLoading(false);
        }
    }
    const handleSearch = async (e) => {
        var inp = e.target;
        searchTable(tableSer.current, inp.value);
    }
    const handleSelect = async (e) => {
        e.target.select();
    }

    useEffect(() => {
        loadUsers();
        setIsLoad(false);
    }, [])


    if (!isload) return <div className="Isexpand">
        <div className="py-2"></div>
        <div className="rounded row w-100">
            <div className="row px-3 w-100">
                <ProgressIntern />
            </div>
        </div>
    </div>;

    return(
        <div>
            <div className="px-3">
                <div className="row w-100 sm-py-3"></div>
                <div className="row justify-between align-items-center">
                    <div className="col-2 ocultar-sm">
                        <h1 className="title-lg my-1">Administradores</h1>
                    </div>
                    <div className="col-4 col-sm-9 py-2">
                        <input type="search" className="inp_add" placeholder="Buscar..." onInput={handleSearch} onClick={handleSelect}/>
                    </div>
                    <div className="col-3 py-2 md-p-2 text-right">
                        <button className="btn-icon btn-primary waves-effect waves-light btn-flt-mov" onClick={abrirModal}>
                            <div className="row w-100 align-items-center">
                                <IoAddSharp className="col col-sm-12 icon-btn plus_btn mr-m ms-sm-rauto w-100" />
                                <span className="col col-sm-12 sm-txt-85">Admin</span>
                            </div>
                        </button>
                    </div>
                </div>
                <div className="row my-2">
                    <table className="table_outline">
                        <thead>
                            <tr>
                                <th className="transparent">Nombre</th>
                                <th className="transparent">Registrado</th>
                                <th className="transparent">Telefono</th>
                                {session.user && session.user.rol == 'Super' ? <th className="transparent ocultar-sm">Acceso</th> : ''}
                                <th className="text-center transparent">Acc</th>
                            </tr>
                        </thead>
                        <tbody ref={tableSer}>
                            {users && users.length > 0 ? users.map((user, ind) => {
                                return(<tr key={'t_u_'+ind}>
                                    <td>
                                        <div className="row gap-1 justify-items-center">
                                            <div className="col-1">
                                                <img src={user.photo != null ? urlbase+user.photo : urlbase+'/images/admin.png'} className="img-profile-med" />
                                            </div>
                                            <div className="col text-left">
                                                <p className="is_link clear-my">{user.name +" "+user.lastname}</p>
                                                <span className="sm-text color-secondary">{user.rol}</span>
                                                <span className="">
                                                    <span className="sm-text-75 color-secondary"> - GLOBAL</span>
                                                </span>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <span className="sm-text w-100">{extractDateFormatR(user.created)}</span>
                                        <p className="w-100 sm-text color-secondary w-100">{extractHour(user.created)}</p>
                                    </td>
                                    <td className="ocultar-sm">
                                        <a className="tag-phone" href={"tel:"+user.phone}>
                                            <IoCall className="icon-phone-tg"/>
                                            <span className="mx-1 phone-number ocultar-sm">{user.phone}</span>
                                        </a>
                                    </td>
                                    {session.user && session.user.rol == 'Super' ? <td>
                                        <div className="tag-phone" id={user._id} onClick={showPassword}>
                                            <IoEyeSharp className="icon-phone-tg"/>
                                            <span className="mx-1 phone-number ocultar-sm">contraseña</span>
                                        </div>
                                    </td> : ''}
                                    <td className="text-center">
                                        <button className="btn btn-sm btn-outline-primary mx-1 ocultar-sm" id={user._id} onClick={handleEdit}>Editar</button>
                                        <button className="btn btn-sm btn-outline-danger ocultar-sm" id={user._id} onClick={handleDelete}>Eliminar</button>
                                        <button className="btn btn-sm btn-outline-transparent waves-effect show-sm" id={user._id} onClick={handleOptions}>
                                            <IoEllipsisVerticalSharp className="icon-opc"/>
                                        </button>
                                    </td>
                                </tr>)
                            }): 'No hay usuarios administradores actualmente'}
                        </tbody>
                    </table>
                </div>
            </div>
            <Modal open={open} setOpen={setOpen} size={"modal-u-sm"} height={"hg-auto"} handleClosed={handleClosed}>
                <AdminForm setOpen={setOpen} isComplete={loadUsers} userId={iduser} refName="administrador" setMessage={setMessage} handleClosed={handleClosed}/>
            </Modal>
            <Modal open={openp} setOpen={setOpenp} size={"modal-u-sm"} height={"hg-auto"} handleClosed={handleClosed}>
                <div className="head_modal justify-items-center">
                    <button className='btn_back waves-effect text-center' onClick={handleBack}>
                        <IoChevronBack className='icon-md' />
                    </button>
                    <h5 className="modal-tittle">Credenciales de acceso</h5>
                </div>
                <div className='w-100 sm-py-2'></div>
                <div className='w-100 sm-py-2'></div>
                <div className="modal_body">
                    <strong>Usuario</strong>
                    <p className="color-dark-300">{credenciales.email}</p>
                    <div className="my-2">
                        <strong>Password</strong>
                        <p className="color-dark-300">{credenciales.password}</p>
                    </div>  
                </div>
            </Modal>
            <Modal open={opend} setOpen={setOpend} size={"modal-u-sm"} height={"hg-auto"} handleClosed={handleClosed}>
                <div className="head_modal justify-items-center">
                    <button className='btn_back waves-effect text-center' onClick={handleBack}>
                        <IoChevronBack className='icon-md' />
                    </button>
                    <h5 className="modal-tittle">¿Eliminar este usuario?</h5>
                </div>
                <div className='w-100 sm-py-2'></div>
                <div className='w-100 sm-py-2'></div>
                <div className="modal_body">
                    <p className="color-red">Este usuario se eliminara permanentemente de la aplicacion</p>
                </div>
                <div className='w-100 py-3'></div>
                <div className='w-100 py-3'></div>
                <div className="modal_footer">
                    <div className="d-flex justify-end">
                        <div>
                            <button className="btn btn-danger waves-effect waves-light" onClick={delUser}>Eliminar</button>
                        </div>
                    </div>
                </div>
            </Modal>
            <OptionMovil open={option} setOpen={setOption} setIdClient={setIdUser} >
                <ul className="list">
                    <div className="item-list waves-effect" id={iduser} onClick={handleEdit}>
                        <div className="cont-icon">
                            <IoCreate className="icon_item"/>
                        </div>
                        <span className="tex_item">Editar</span>
                    </div>
                    <div className="item-list waves-effect" id={idref} onClick={handleDelete}>
                        <div className="cont-icon">
                            <IoRemoveCircle className="icon_item"/>
                        </div>
                        <span className="tex_item">Eliminar</span>
                    </div>
                </ul>
            </OptionMovil>
            {message ? <SnackBar text={message.text} setMessage={setMessage} type={message.type} close={false}/> : ''}
        </div>
    )
}