import {IoPersonCircleSharp, IoPersonAddSharp, IoMusicalNoteSharp, IoChevronForwardOutline} from "react-icons/io5";
import { MdMoney } from "react-icons/md";
import {useState, useEffect} from 'react'
import {Link } from "react-router-dom";
import {useApp} from "../../context/appContext";
import {extractHour, extractDateFormatR} from "../../components/utility/utils"
import {IoChevronForward, IoClose, IoCheckmark} from "react-icons/io5";
import { getCreaciones, getLibres, getApartadas, getGrabadas } from "../../db";

export default function Tablon(){
    const {online, session, setLoading, urlbase, myHeaders} = useApp();
    const [access, setAccess] = useState([]);
    const [studies, setStudies] = useState([]);
    const [songs, setSongs] = useState([]);
    const [opens, setOpenes ] = useState(false);
    const [idview, setIdView] = useState(null);
    const [statistics, setStatistics] = useState({
        creaciones: 0,
        libres: 0,
        apartadas: 0,
        grabadas: 0
    });

    const handleClosed = (e) => {
        setOpenes(false);
        setIdView(null);
        e.stopPropagation();
    }
    const loadStatistics = async (date) => {
        if (online) {
            try {
                setLoading(true);
                const response = await fetch(urlbase+"/app/get_statistics", {
                    method: 'GET',
                    headers: myHeaders(),
                })
                const data = await response.json();
                const access = data.accesos;
                const estudios = data.estudios;
                setStatistics(data.datos);
                if (access && access.length > 0) {
                    setAccess(access);
                }
                if (estudios && estudios.length > 0) {
                    setStudies(estudios);
                }
                setLoading(false);
            } catch (err) {
                console.log(err);
                setLoading(false);
            }
        }else{
            const t_creaciones = await getCreaciones();
            const t_libres = await getLibres();
            const t_apartadas = await getApartadas();
            const t_grabadas = await getGrabadas();
            setStatistics({
                creaciones: t_creaciones.length,
                libres: t_libres.length,
                apartadas: t_apartadas.length,
                grabadas: t_grabadas.length
            });
            setLoading(false);
        }
    }
    const handleView = async (e) => {
        setIdView(e.currentTarget.id);
        setOpenes(true);
    }

    useEffect(() => {
        loadStatistics();
    }, []);



    return(
        <div className="Isexpand sm-py-3">
            <div className="row my-2 w-100">
                <div className="col">
                    <div className="row my-2 w-100">
                        <div className="wrapper-4 sm-wrapper-2 w-100 px-3">
                            <div className="card-desin-t max-h-185">
                                <IoMusicalNoteSharp className="icon-tablon"/>
                                <h1 className="number_tt py-1">{statistics.sharings}</h1>
                                <div className="row w-100 justify-center justify-align-items-center">
                                    <h2 className="title-crd-t clear-m-p">Enviadas </h2>
                                </div>
                            </div>
                            <div className="card-desin-t max-h-185">
                                <IoPersonAddSharp className="icon-tablon"/>
                                <h1 className="number_tt py-1">{statistics.autores}</h1>
                                <div className="row w-100 justify-center justify-align-items-center">
                                    <h2 className="title-crd-t clear-m-p">Autores </h2>
                                </div>
                            </div>
                            <div className="card-desin-t max-h-185">
                                <IoPersonCircleSharp className="icon-tablon"/>
                                <h1 className="number_tt py-1">{statistics.artistas}</h1>
                                <div className="row w-100 justify-center justify-align-items-center">
                                    <h2 className="title-crd-t clear-m-p">Artistas </h2>
                                </div>
                            </div>
                            <div className="card-desin-t max-h-185">
                                <MdMoney className="icon-tablon bk_resl_red"/>
                                <h1 className="number_tt py-1">{statistics.estudios}</h1>
                                <div className="row w-100 justify-center justify-align-items-center">
                                    <h2 className="title-crd-t clear-m-p">Estudios </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="row my-2 w-100">
                        <div className="wrapper-3 sm-wrapper-2 w-100 px-3">
                            <div className="card-desin-t max-h-185">
                                <img src="/images/logo_iso.png" className="icon-tablon-img"/>
                                <h1 className="number_tt py-1">{statistics.libres}</h1>
                                <div className="row w-100 justify-center justify-align-items-center">
                                    <h2 className="title-crd-t clear-m-p">Libres </h2>
                                </div>
                            </div>
                            <div className="card-desin-t max-h-185">
                                <img src="/images/logo_iso.png" className="icon-tablon-img"/>
                                <h1 className="number_tt py-1">{statistics.apartadas}</h1>
                                <div className="row w-100 justify-center justify-align-items-center">
                                    <h2 className="title-crd-t clear-m-p">Apartadas </h2>
                                </div>
                            </div>
                            <div className="card-desin-t max-h-185">
                                <img src="/images/logo_iso.png" className="icon-tablon-img"/>
                                <h1 className="number_tt py-1">{statistics.grabadas}</h1>
                                <div className="row w-100 justify-center justify-align-items-center">
                                    <h2 className="title-crd-t clear-m-p">Grabadas </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            {session && session.user.rol != 'Redactor' ? <div className="row px-3 gap-3">
                <div className="col col-sm-12 bg-white border-text-black-50 rounded">
                    <div className="row w-100">
                        <div className="row w-100 px-3">
                            <h3 className="my-1 py-1 border-bottom w-100">Nuevos Estudios</h3>
                            <table className="table_outline">
                                <thead>
                                    <tr>
                                        <th className="text-center ocultar-sm"> </th>
                                        <th>Artista</th>
                                        <th className="text-right">Registrado</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {studies.map((study, ind) => {
                                        return(<tr key={'t_u_'+ind}>
                                            <td className="text-center ocultar-sm"></td>
                                            <td>
                                                <div className="row gap-1 justify-items-center">
                                                    <div className="col-1">
                                                        <MdMoney className="icon-tablon bk_resl_red"/>
                                                    </div>
                                                    <div className="col text-left">
                                                        <p className="tag_inl is_link clear-my" id={study._id} onClick={handleView}>{study ? study.name : '-'}</p>
                                                        <p className="text-secondary sm-text-75 clear-my">
                                                            <div className="row w-100 justify-items-center">
                                                                <span>{study.city ? study.city : '-'}</span>
                                                            </div>
                                                        </p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="text-right">
                                                <span className="sm-text w-100">{extractDateFormatR(study.created)}</span>
                                                <p className="w-100 sm-text color-secondary w-100">{extractHour(study.created)}</p>
                                            </td>
                                        </tr>)
                                    })}
                                </tbody>
                            </table>
                            <Link to={'/app/studies'} className="row w-100 py-2 is_link justify-center justify-items-center">
                                <span className="col-1 text-center mr-1">Ver todos</span>
                                <IoChevronForward className="col-1 icon-sm"/>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="col col-sm-12 md-p-2 bg-white border-text-black-50 rounded">
                    <div className="row w-100">
                        <div className="row w-100 px-3">
                            <h3 className="my-1 py-1 border-bottom w-100">Accesos a la administración</h3>
                            <table className="table_outline">
                                <tbody>
                                    {access && access.length > 0 ? access.map((acc) => {
                                        let d = new Date(acc.date_in);
                                        return(
                                            <tr key={acc._id}>
                                                <td><p className="p-1"></p></td>
                                                <td>
                                                    {acc.user != null ? <div className="row gap-1 justify-items-center">
                                                        <div className="col-1 mr-1">
                                                            <img src="/images/auth.png" className="img-profile-sm" />
                                                        </div>
                                                        <div className="col text-left">
                                                            <p className="tag_inl is_link clear-my">{acc.user ? acc.user.name+" "+acc.user.lastname : '-'}</p>
                                                            <p className="text-secondary fs-85">{extractDateFormatR(d)+" "+extractHour(d)}</p>
                                                        </div>
                                                    </div> :
                                                    <p className="is_link clear-my">Desconocido</p>}
                                                </td>
                                                <td className="ocultar-sm">
                                                    <div className="limpio">
                                                        {acc.login ? 
                                                            <span className="mx-1 sm-text tag-done sm-text ocultar-sm"><IoCheckmark className="icon-sm align-icon"/> exitoso</span> 
                                                            : <span className="mx-1 sm-text tag-fail sm-text ocultar-sm"><IoClose className="icon-sm align-icon"/> fallido</span>}
                                                    </div>
                                                </td>
                                                <td className="ocultar-sm">
                                                    <div className="limpio">
                                                        <span className="text-secondary ocultar-md fs-sm">usuario </span>
                                                        {acc.login ? 
                                                            <span className="mx-1 ocultar-sm">
                                                                <IoCheckmark className="icon-sm align-icon"/>
                                                            </span> 
                                                            : <span className="mx-1 text-sm ocultar-sm"><IoClose className="icon-sm align-icon"/> {acc.code}</span>}
                                                    </div>
                                                </td>
                                                <td className="ocultar-sm">
                                                    <div className="limpio">
                                                        <span className="text-secondary ocultar-md fs-sm">contraseña </span>
                                                        {acc.login ? 
                                                            <span className="mx-1 ocultar-sm">
                                                                <IoCheckmark className="icon-sm align-icon"/>
                                                            </span> 
                                                            : <span className="mx-1 text-sm ocultar-sm"><IoClose className="icon-sm align-icon"/> {acc.password}</span>}
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }): 'No hay accesos todavia'}
                                </tbody>
                            </table>
                            <div className="row w-100 py-2 is_link justify-center justify-items-center">
                                <span className="col-1 text-center mr-1">Ver todos</span>
                                <IoChevronForward className="col-1 icon-sm"/>
                            </div>
                        </div>  
                    </div>
                </div>
                <div className='w-100 sm-py-2'></div>
                <div className='w-100 sm-py-2'></div>
            </div> : ''}
        </div>
    )
}