import {useState, useEffect} from 'react'
import {useApp} from "../../context/appContext";
import {IoChevronBack, IoBusinessOutline } from "react-icons/io5";
import ProgressModal from "../ui/ProgressModal";
import {extractHour, extractDateR} from "../utility/utils";
import { getEditora } from "../../db";

export default function Editora({studyId, setOpen}){
    const {online, urlbase, myHeaders} = useApp();
    const [isload, setIsLoad] = useState(true);
    const [study, setStudy] = useState({
        name: "",
        country: "",
        address: "",
        state: "",
        city: ""
    });

    const handleBack = (e) => {
        setTimeout(() => {
            setOpen(false);
            setIsLoad(true);
        }, 200);
    }
    const handleClosed = (e) => {
        setOpen(false);
    }
    const loadStudy = async (id) => {
        if (online) {
            const res = await fetch(urlbase+"/app/get_study/"+id, {
                method: 'GET',
                headers:myHeaders()
            })
            const response = await res.json();
            const data = response.study;
            setStudy(data);
            setIsLoad(false);
        }else{
            let data = await getEditora(id);
            setStudy(data);
            setIsLoad(false);
        }
    }

    useEffect(() => {
        if (studyId) {
            loadStudy(studyId);
        }
    }, [studyId]);


    if (isload) return <div className="fadeIn">
        <div className="py-2"></div>
        <div className="rounded row w-100">
            <div className="row px-3 w-100">
                <ProgressModal />
            </div>
        </div>
    </div>;


    return(
        <div>
            <div className="head_modal justify-items-center">
                <button className='btn_back waves-effect text-center' onClick={handleBack}>
                    <IoChevronBack className='icon-md' />
                </button>
                <h5 className='modal-tittle'>INFO ESTUDIO</h5>
            </div>
            <div className='modal_body'>
                <div className="row w-100">
                    <div className="row justify-center w-100">
                        <div className='w-100 sm-py-2'></div>
                        <div className='w-100 sm-py-2'></div>
                        <div className="col-8 text-center col-sm-12">
                            <img src={urlbase+'/images/fondo_lo_3.jpg'} className="img-profile" />
                            <div className='row w-100'>
                                <div className='w-100 text-center my-1'>
                                    <span className='tg-state-apa'>
                                        <IoBusinessOutline className="icn-sate" />
                                        {study.city}
                                    </span>
                                </div>
                                <h2 className="w-100">{study.name}</h2>
                            </div>
                            <div className='w-100 py-2'></div>
                            <div className='row w-100'>
                                <div className="list-ficha w-100 px-1 md-p-2 text-left">
                                    <p className="row w-100 justify-between">
                                        <strong className="col sm-mov-85">CREADO</strong>
                                        <span className="col text-right text-secondary">{(extractDateR(study.created))} {extractHour(study.created)}</span>
                                    </p>
                                    <p className="row w-100 justify-between my-1">
                                        <strong className="col sm-mov-85">MODIFICADO</strong>
                                        <span className="col text-right text-secondary">{(extractDateR(study.modified))} {extractHour(study.modified)}</span>
                                    </p>
                                    <p className="row w-100 justify-between">
                                        <strong className="col sm-mov-85">Dirección</strong>
                                        <span className="col text-right is_link text-secondary">{study.address}</span>
                                    </p>
                                </div>
                                <div className='row gap-2 py-2 w-100'>
                                    <div className="col-1">
                                        <img src={'/images/usuario.png'} className="img-profile-sm" />
                                    </div>
                                    <div className="col text-left">
                                        <strong className="tag_inl clear-my">Información de ubicación</strong>
                                    </div>
                                </div>
                                <div className="list-ficha w-100 px-1 md-p-2 text-left">
                                    <p className="row w-100 justify-between">
                                        <strong className="col sm-mov-85">Ciudad</strong>
                                        <span className="col text-right">{study.city}</span>
                                    </p>
                                    <p className="row w-100 justify-between my-1">
                                        <strong className="col sm-mov-85">Estado</strong>
                                        <span className="col text-right is_link text-secondary">{study.state}</span>
                                    </p>
                                    <p className="row w-100 justify-between">
                                        <strong className="col sm-mov-85">Pais</strong>
                                        <span className="col text-right is_link text-secondary">{study.country}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='w-100 py-3'></div>
        </div>
    )
}