import MovilMenu from "../components/ui/MovilMenu";
import Header from "../components/ui/Header";
import SideMenu from "../components/ui/SideMenu";
import ProtectedAdmin from "../components/protected/ProtectedAdmin"
import {IoApps, IoPerson, IoMusicalNotesSharp, IoListSharp, IoPeopleSharp, IoPersonCircleSharp, IoPersonAddSharp, IoSend, IoLayers } from "react-icons/io5";
import { MdHomeFilled, MdMoney, MdSupervisorAccount } from "react-icons/md";
import {useApp} from "../context/appContext";
import {Outlet} from 'react-router-dom'

export default function Dashboard({children}) {
    const {online, playlist, setPlaylist} = useApp();
    let items = [
        {name:'Inicio', url:'/app/tablon', icon:(<MdHomeFilled className="icon_it_he fs-hm"/>)},
        {name:'Estudios', type:'app', url:'/app/studies', icon:(<MdMoney className="icon_it_he"/>)},
        {name:'Usuarios', type:'app', url:'/app/users', icon:(<MdSupervisorAccount className="icon_it_he"/>)},
        {name:'Adminis', url:'/app/admins', icon:(<IoPerson  className="icon_it_he"/>)}
    ];
    let itemsM = [
        {name:'Inicio', url:'/app/tablon', icon:(<MdHomeFilled className="icon_it_mv fs-hm-m"/>)},
        {name:'Creaciones', url:'/app/creaciones', icon:(<IoMusicalNotesSharp  className="icon_it_mv"/>)},
        {name:'Catalogo', url:'/app/catalogo', icon:(<IoListSharp className="icon_it_mv"/>)},
        {name:'Clientes', url:'/app/clientes', icon:(<IoPeopleSharp className="icon_it_mv"/>)},
        {name:'Mas', url:'/app/more_options', icon:(<IoApps className="icon_it_mv"/>)}
    ];
    

    return(
        <>
            <ProtectedAdmin>
                <Header></Header>
                <div className="d-flex">
                    <SideMenu items={items}>
                        <div className="py-2"></div>
                    </SideMenu>
                    <main className="container_main col-md-12 col-lg-10 ms-sm-auto px-md-4 fadeIn">
                        <div className="p-2 md-p-2">
                            <Outlet />
                        </div>
                    </main>
                </div>
                <MovilMenu items={itemsM} online={online}></MovilMenu>
            </ProtectedAdmin>
        </>
    )
}



        