import './App.css';
import 'node-waves/dist/waves.css'
import 'react-widgets/styles.css';
import { useEffect } from 'react'
import { useLocation } from "react-router-dom";
import { Route, Routes } from 'react-router-dom';
import Waves from 'node-waves'
import Dashboard from './layouts/Dashboard';
import Login from './pages/Login';
import Tablon from './pages/admin/Tablon';
import Studies from './pages/admin/Studies';
import Usuarios from './pages/admin/Usuarios';
import SuperAdmins from './pages/admin/SuperAdmins';
import PerfilUpdate from './pages/admin/PerfilUpdate'
import {AppProvider} from './context/appContext';
import ProtectedPublic from "./components/protected/ProtectedPublic"

function App() {
    const location = useLocation();
    useEffect(() => {
      let config = {
        duration: 600
      }
      Waves.attach('.button', ['waves-effect', 'waves-float']);
      Waves.init(config);
    })
    useEffect(() => {
      window.scrollTo(0,0);
    }, [location]);
    
    return (

      <AppProvider>
        <Routes>
          <Route path="/" element={ <ProtectedPublic> <Login></Login> </ProtectedPublic>}></Route>
          <Route path="app" element={<Dashboard />}>
            <Route index element={<Tablon></Tablon>} />
            <Route path="tablon" element={<Tablon></Tablon> } />
            <Route path="studies" element={<Studies></Studies> } />
            <Route path="users" element={<Usuarios></Usuarios>} />
            <Route path="admins" element={<SuperAdmins></SuperAdmins>} />
            <Route path="foto_perfil" element={<PerfilUpdate></PerfilUpdate>} />
          </Route>
          <Route path="*" element={<h1>No Encontrado</h1>}></Route>
        </Routes>

      </AppProvider>
    );
}

export default App;
