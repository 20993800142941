import {useState, useEffect} from 'react'
import {useApp} from "../../context/appContext";
import CroppieImage from "../../components/ui/CroppieImage";
import AlertDialog from "../../components/ui/AlertDialog";
import {separarImagen} from "../../components/utility/utils"
import Compress from 'compress.js'

export default function PerfilUpdate(){
    const {loadSessionToken, session, setLoading, urlbase, myHeaders} = useApp();
    const [image_f, setImagef] = useState(null);
    const [selected, setSelected] = useState(false);
    const [alert, setAlert ] = useState(null);
    const [image, setImage] = useState("/images/usuario.png");
    const reader = new FileReader();

    const completeImage = (croppedImage) => {
        if (croppedImage) {
            setImagef(croppedImage);
        }
    }
    const handleSubmit = async (e) => {
      let button = e.currentTarget;
      try {
          e.preventDefault();
          if (image_f != null && selected){
            let formData = new FormData();
            formData.append('photo', image_f);
            setLoading(true);
            button.disabled = true;
            let response = await fetch(urlbase+"/app/sp_update_foto_perfil", {
                method: 'POST',
                headers: myHeaders(true),
                body: formData
            })
            const res = await response.json();
            if(res.clave == "exito"){
                changePhotoSession(res.url);
                setImagef(null);
                setSelected(false);
            }else{
                setAlert(res.mensaje);
            }
            setLoading(false);
            button.disabled = false;
          }else{
            setAlert("Debes elegir una nueva foto");
          }
      } catch (err) {
          console.log(err);
          setAlert("Error en el servidor");
          setLoading(false);
          button.disabled = false;
      }
    }
    const handleChangeFile = (e) => {
        let file = e.target.files[0];
        var compress = new Compress();
        var files = [file];
        compress.compress(files, {size: 2,quality: 0.85,maxWidth: 1920,maxHeight: 1920,resize: true})
        .then((images) => {
            var img = images[0];
            var imagen = img.prefix+img.data.toString();
            var file_t = separarImagen(imagen);
            if (file_t) {
                setSelected(true);
                reader.onload = function (e) {
                    setImage(e.target.result);
                }
                reader.readAsDataURL(file_t);
            }
        });
    }
    const changePhotoSession = async (url_p) => {
        if (url_p) {
            let ses = JSON.parse(localStorage.getItem('session'));
            if (ses) {
                ses.user.photo = url_p;
                let sess = await loadSessionToken(ses);
                let elmd = document.getElementById('avatar-app');
                elmd.src = urlbase+url_p;
            }
        }
    }

    useEffect(() =>{
        let user = session.user;
        if (user.photo) {
            setImage(urlbase+user.photo);
        }
    }, []);

    return(
        <div className="px-3 Isexpand">
            <h1 className="py-1">Foto de perfil</h1>
            <div className="row gap-2">
              <div className="col col-md-12">
                <div className="col-8 col-md-12">
                    <div className="row my-2 text-center">
                        <button className="btn btn-upload w-100">Elegir foto
                            <input type="file" className="file-in-button" onChange={handleChangeFile}/>
                        </button>
                    </div>
                    <CroppieImage image={image} aspect={ 5 / 5} getCompleteCorp={completeImage}></CroppieImage>
                    <div className="row my-2 text-center">
                      <button className="btn btn-primary w-100" onClick={handleSubmit} disabled={selected ? false : true}>Actualizar</button>
                  </div>
                </div>
              </div>
              <div className="col ocultar-med">
                <p className="my-2 col-5 color-secondary">
                  Esta fotografia sera la encargada de representar tu usuario en el sistema
                </p>
                <p className="my-2 col-5 color-secondary">
                  Eligé una fotografia de buena calidad, y recortala para obtener un mejor resultado.
                </p>
                <p className="my-2 col-5 color-secondary">
                  Puedes cambiar la foto de perfil tantas veces como quieras.
                </p>
                <div className="col-8 my-3">
                    {alert !== null ? (<div className="row my-2">
                        <AlertDialog mensaje={alert} setMensaje={setAlert} time={3000} closed={false}/>
                    </div>) : ''}
                </div>
              </div>
            </div>  
        </div>
    )
}