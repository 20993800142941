import {useState, useEffect} from 'react'
import AlertDialog from "../ui/AlertDialog";
import {useApp} from "../../context/appContext";
import {validEmail} from "../utility/utils"
import ProgressModal from "../ui/ProgressModal";
import NotConection from "../ui/NotConection";
import {IoChevronBack} from "react-icons/io5";


export default function StudyForm({open, setOpen, isComplete, studyId, refName, setMessage, handleClosed}) {
    const {online, setLoading, urlbase, myHeaders} = useApp();
	const [alert, setAlert ] = useState(null);
    const [isload, setIsLoad] = useState(true);
    const [admins, setAdmins] = useState([]);
	const [study, setStudy] = useState({
        name: "",
        country: "",
        address: "",
        admin: "",
        state: "",
        city: ""
    });

    const handleBack = (e) => {
        setTimeout(() => {
            handleClosed();
            setOpen(false);
            setAlert(null);
            window.scrollTo(0,0);
            setIsLoad(true);
        }, 200);
    }
	const handleSubmit = async (e) => {
        let button = e.currentTarget;
        try {
            e.preventDefault();
            if (study.name != ""){
                setLoading(true);
                button.disabled = true;
            	var response = null;
	            if (studyId) {
	            	response = await fetch(urlbase+"/app/update_study/"+studyId, {
		                method: 'PUT',
		                headers: myHeaders(),
		                body: JSON.stringify(study)
		            })
	            }else{
	            	response = await fetch(urlbase+"/app/save_study", {
		                method: 'POST',
		                headers: myHeaders(),
		                body: JSON.stringify(study)
		            })
	            }
	            const res = await response.json();
	            if(res.clave && res.clave == "exito"){
                    if (studyId) {
                        setMessage({text: "Estudio actualizado", type:"done"});
                    }else{
                        setMessage({text: "Estudio guardadado", type:"done"});
                    }
	                setOpen(false);
	                isComplete();
	                setStudy({name: "", address: "", country: "", state: "", city: ""});
                    window.scrollTo(0,0);
                    setIsLoad(true);
	            }else{
                    setAlert(res.mensaje);
                }
                setLoading(false);
                button.disabled = false;
            }else{
            	setAlert("Completa los campos nombre");
            }
        } catch (err) {
            console.log(err);
            setAlert("Error en el servidor");
            setLoading(false);
            button.disabled = false;
        }
    }
    const loadStudy = async (id) => {
    	const res = await fetch(urlbase+"/app/get_study/"+id, {
            method: 'GET',
            headers:myHeaders()
        })
    	const response = await res.json();
    	const data = response.study;
        const adminsdb = response.admins;
        setIsLoad(false);
    	setStudy({name:data.name, address:data.address, admin:data.admin, country:data.country, state:data.state, city:data.city});
        setAdmins(adminsdb);
    }
    const handleChange = (e) => {
        setStudy({...study, [e.target.name]: e.target.value});
        setAlert(null);
    }
    useEffect(() => {
        setStudy({name: "", address: "", admin: "", country: "", state: "", city: ""});
        setAlert(null);
    	if (studyId != null) {
            setIsLoad(true);
    		loadStudy(studyId);
    	}else{
            setIsLoad(false);
        }
    }, [studyId]);


    if (!online) return <div className="fadeIn">
        <div className="py-2"></div>
        <div className="rounded row w-100">
            <div className="row px-3 w-100">
                <NotConection handleClosed={handleBack}/>
            </div>
        </div>
    </div>;

    if (isload) return <div className="fadeIn">
        <div className="py-2"></div>
        <div className="rounded row w-100">
            <div className="row px-3 w-100">
                <ProgressModal />
            </div>
        </div>
    </div>;


	return (
		<div>
            <div className="head_modal justify-items-center">
                <button className='btn_back waves-effect text-center' onClick={handleBack}>
                    <IoChevronBack className='icon-md' />
                </button>
                <h5 className="modal-tittle">{studyId != null ? 'Editar' : 'Nueva estudio'}</h5>
            </div>
            <div className='w-100 sm-py-2'></div>
            <div className='w-100 sm-py-2'></div>
            <div className="modal_body">
                <div className="row">
                    <label className="lb-inp-usm mb-1">Nombre del estudio</label>
                    <input type="text" className="inp_add text-uppercase" value={study.name} onChange={handleChange} name="name" placeholder="Nombre profesional" autoComplete="off"/>
                </div>
                <div className="row my-1">
                    <label className="lb-inp-usm mb-1">Pais</label>
                    <input type="text" className="inp_add" value={study.country} onChange={handleChange} name="country" placeholder="Mexico" autoComplete="off"/>
                </div>
                <div className="row my-1">
                    <label className="lb-inp-usm mb-1">Encargado</label>
                    <div className='position-relative w-100'>
                        <select className="inp_add clear_sl_p" value={study.admin} name="admin" onChange={handleChange}>
                            <option value="">Elegir...</option>
                            {admins && admins.length > 0 ? admins.map((admin, ind) => {
                                return (<option value={admin._id}>{admin.name} {admin.lastname}</option>)
                            }) : ''}
                        </select>
                    </div>
                </div>
                <div className="row my-1">
                    <label className="lb-inp-usm mb-1">Direccion</label>
                    <input type="text" className="inp_add" value={study.address} onChange={handleChange} name="address" placeholder="Direccion del estudio" autoComplete="off"/>
                </div>
                <div className="row my-1">
                    <label className="lb-inp-usm mb-1">Estado</label>
                    <input type="text" className="inp_add" value={study.state} onChange={handleChange} name="state" placeholder="Sinaloa" autoComplete="off"/>
                </div>
                <div className="row my-1">
                    <label className="lb-inp-usm mb-1">Ciudad</label>
                    <input type="text" className="inp_add" value={study.city} onChange={handleChange} name="city" placeholder="Mazatlan" autoComplete="off"/>
                </div>
                {alert !== null ? (<div className="row my-2">
                    <AlertDialog mensaje={alert} setMensaje={setAlert} time={3000} closed={false}/>
                </div>) : ''}
            </div>
            <div className='w-100 py-3'></div>
            <div className='w-100 py-3'></div>
            <div className="modal_footer">
                <div className="d-flex justify-end">
                    <div>
                        <button className="btn btn-primary waves-effect waves-light" onClick={handleSubmit}>{studyId ? 'APLICAR' : 'CREAR'}</button>
                    </div>
                </div>
            </div>
        </div>
	)
}